import React, { useEffect, useState, useRef } from "react";
import { Tab, Nav } from "react-bootstrap";
import moment from "moment/moment";
import { Pagination } from "@mui/material";

import { getDirectMember, getDirectTeam, getUserTeam } from "../../../helpers/api";
import { useSelector } from "react-redux";
import { Loader } from "../Dashboard/Loader";
const MyTeam = () => {
  const { walletAddress } = useSelector((action) => {
    return action.auth;
  });

  const [apidata, setApidata] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   getDirectMember(walletAddress, page).then((res) => {
  //     setTotal(res?.total);
  //     setApidata(res?.data);
  //     setLoading(true);
  //   });
  // }, [page, walletAddress]);

  // const handleChange = (e, p) => {
  //   getDirectMember(walletAddress, p).then((res) => {
  //     setTotal(res?.total);
  //     setApidata(res?.data);
  //     setLoading(true);
  //   });
  //   //console.log(p, page, "page:::");
  //   setPage(p);
  // };
  useEffect(() => {
    getUserTeam(walletAddress).then((res) => {
      console.log(res, "dkddkdkdkd")
      setApidata(res?.downline);
      setLoading(true);
    });
  }, [page, walletAddress]);
  console.log(apidata,'apidata::::')
  return (
    <>
      {!loading ? (
        <Loader />
      ) : (
        <div className="row">
          <div className="col-xxl-12">
            <div className="card">
              <Tab.Container defaultActiveKey="All">
                <div className="card-header border-0 pb-2 flex-wrap">
                  <h4 className="heading me-2">My Downline Team</h4>

                </div>
                <div className="card-body pt-2">
                  <div
                    id="history_wrapper"
                    className="table-responsive dataTablehistory"
                  >
                    <div className="dataTables_wrapper no-footer">
                      <table
                        id="example"
                        className="table shadow-hover dataTable display"
                        style={{ minWidth: "845px" }}
                      >
                        <thead>
                          <tr className="text-white">
                            <th className="text-white">Txn Hash</th>
                            <th className="text-white">User Address</th>
                            <th className="text-white"> User Id</th>
                            <th className="text-white"> Total Investment (USDT)</th>
                            <th className="text-white">Total Direct Business (USDT)</th>
                            <th className="text-white">Total Team Business (USDT)</th>
                            <th className="text-white"> Joined On</th>
                          </tr>
                        </thead>
                        <tbody>
                          {apidata?.length > 0 ? (
                            apidata?.map((e, index) => (
                              <tr key={index}>
                                <td>
                                  {" "}
                                  {e?.txHash?.slice(0, 6)}...
                                  {e?.txHash?.slice(-5)}
                                  <a
                                    target="_blank"
                                    href={`https://bscscan.com/tx/${e?.txHash}`}
                                  >
                                    <i className="fas fa-external-link-alt fs-10 ms-1 text-success"></i>
                                  </a>
                                </td>
                                <td>
                                  {" "}
                                  {e?.user?.slice(0, 6)}...
                                  {e?.user?.slice(-5)}
                                </td>
                                <td>{e?.userId}</td>
                              
                        
                          
                                <td> {e?.stake_amount}</td>
                                <td> {e?.stakedirectbusiness}</td>
                                <td> {e?.directplusteambiz}</td>
                                <td>
                                  {" "}
                                  {moment(
                                    Number(e.timestamp) * 1000
                                  ).fromNow()}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={7}>
                                <div className="text-center  mt-4 mb-2 fw-bold fs-5 dash-heading">
                                  No Data Found.
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {/* <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                        <div className="dataTables_info"></div>
                        <div
                          className="dataTables_paginate paging_simple_numbers mb-0"
                          id="application-tbl1_paginate"
                        >
                          <Pagination
                            color="primary"
                            count={Math.ceil(total / 10)}
                            onChange={handleChange}
                          />
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MyTeam;
