import { LuBoxes } from "react-icons/lu";
import { BiMoneyWithdraw, BiStreetView } from "react-icons/bi";
import { VscSymbolConstant } from "react-icons/vsc";
import { GrGroup } from "react-icons/gr";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { FaStaylinked, FaTicketAlt } from "react-icons/fa";

export const MenuList = [
  //Dashboard
  {
    title: "My Reports",
    classsChange: "mm-collapse",
    // to: "/dashboard",
    iconStyle: <i className="material-icons">grid_view</i>,
    content: [

      {
        title: "Staking History",
        classsChange: "mm-collapse",
        iconStyle: <BiMoneyWithdraw size={30} color="#fff" />,
        to: "/stakingBonus",
      },
      {
        title: "Yield History",
        classsChange: "mm-collapse",
        iconStyle: <BiMoneyWithdraw size={30} color="#fff" />,
        to: "/roiBonus",
      },

    ],
  },
  {
    title: "Team Reports",
    classsChange: "mm-collapse",
    // to: "/dashboard",
    iconStyle: <i className="material-icons">grid_view</i>,
    content: [

      {
        title: "My Directs",
        classsChange: "mm-collapse",
        iconStyle: <AiOutlineUsergroupAdd />,

        to: "/partner",
      },

      {
        title: "My Team ",
        classsChange: "mm-collapse",
        iconStyle: <GrGroup />,
        to: "/myteam",
      },


    ],
  },
  {
    title: "Reward Report",
    classsChange: "mm-collapse",
    // to: "/dashboard",
    iconStyle: <i className="material-icons">grid_view</i>,
    content: [

      {
        title: "Registration Reward",
        classsChange: "mm-collapse",
        iconStyle: <BiMoneyWithdraw size={30} color="#fff" />,
        to: "/levelIncome",
      },


      {
        title: "Activation Reward",
        classsChange: "mm-collapse",
        iconStyle: <BiMoneyWithdraw size={30} color="#fff" />,
        to: "/levelSponser",
      },
      {
        title: "Level Income Reward",
        classsChange: "mm-collapse",
        iconStyle: <i className="material-icons">trending_up</i>,

        to: "/Level",
      },
      {
        title: "CTR Reward",
        classsChange: "mm-collapse",
        iconStyle: <LuBoxes />,
        to: "/reward&Recognition",
      },




    ],
  },
  {
    title: "Withdraw Report",
    classsChange: "mm-collapse",
    // to: "/dashboard",
    iconStyle: <i className="material-icons">grid_view</i>,
    content: [

      {
        title: " Withdraw Reward",
        classsChange: "mm-collapse",
        iconStyle: <VscSymbolConstant size={30} color="#fff" />,
        to: "/claimBonus",
      },



    ],
  },
  {
    title: "Reward Status",
    classsChange: "mm-collapse",
    // to: "/dashboard",
    iconStyle: <i className="material-icons">grid_view</i>,
    content: [

      {
        title: "Reward",
        classsChange: "mm-collapse",
        iconStyle: <FaStaylinked size={30} color="#fff" />,
        to: "/reward",
      },



    ],
  },
  {
    title: "VSM Support",
    classsChange: "mm-collapse",
    iconStyle: <FaStaylinked size={30} color="#fff" />,
    to: "/help",
  },



];
