import React, { useEffect, useState } from "react";
import { Tab } from "react-bootstrap";
import moment from "moment/moment";
import { Pagination } from "@mui/material";

import { getUserStakedClaim, withDrawList } from "../../../helpers/api";
import { useSelector } from "react-redux";
import { Loader } from "../Dashboard/Loader";
const ClaimBonus = () => {
  const { walletAddress, userToken } = useSelector((action) => {
    return action.auth;
  });

  const USER_TOKEN = userToken?.value;
  const [apidata, setApidata] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  function getStakingClaim() {
    withDrawList(walletAddress).then((res) => {
      // console.log(res, "staked");
      // setTotal(res?.total);
      setApidata(res?.data);
      setLoading(true);
    });
  }

  useEffect(() => {
    getStakingClaim();
   
  }, [walletAddress]);

  // const handleChange = (e, p) => {
  //   getStakingClaim(p);
   
  //   setPage(p);
  // };
  // console.log(apidata,"apidata;::")
  return (
    <div>
      {!loading ? (
        <Loader />
      ) : (
        <div className="row">
          <div className="col-xxl-12">
            <div className="card">
              <Tab.Container defaultActiveKey="All">
                <div className="card-header border-0 pb-2 flex-wrap">
                  <h4 className="heading me-2">Withdraw Bonus</h4>
                </div>
                <div className="card-body pt-2">
                  <div
                    id="history_wrapper"
                    className="table-responsive dataTablehistory"
                  >
                    <div className="dataTables_wrapper no-footer">
                      <table
                        id="example"
                        className="table shadow-hover dataTable display"
                        style={{ minWidth: "845px" }}
                      >
                        <thead>
                          <tr>
                          <th className="text-white">Transaction  Hash</th>

                            <th className="text-white">User address</th>
                           

                            <th className="text-white"> Withdraw Amount ($)</th>
                            <th className="text-white"> Token Amount </th>
                            <th className="text-white">Status</th>
                            <th className="text-white">Income Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {apidata?.length > 0 ? (
                            apidata.map((e, index) => (
                              <tr key={index}>
                                       <td>
                                  {e?.trxnHash?.slice(0, 6)}...
                                  {e?.trxnHash?.slice(-5)}{" "}
                                  <a
                                    target="_blank"
                                    href={`https://bscscan.com/tx/${e?.trxnHash}`}
                                  >
                                    <i className="fas fa-external-link-alt fs-10 ms-1 text-success"></i>
                                  </a>
                                </td>
                                <td>
                                  {" "}
                                  {e?.user.slice(0, 6)}...
                                  {e?.user.slice(-5)}
                                </td>
                               
                                <td>{e?.withdrawAmount}</td>
                                <td>{e?.isapprove && e?.sendToken!=0 ? e?.sendToken :e?.isapprove && e?.sendToken==0 ? e?.withdrawToken:'...'}</td>
                               
                                <td>{e?.isapprove ? <span className="badge bg-success">Success </span> : <span className="badge bg-warning">Pending </span>} </td>
                           
                                <td>
                                  {" "}
                                  {moment(
                                                (e.createdAt)
                                              ).fromNow()}

                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={7}>
                                <div className="text-center  mt-4 mb-2 fw-bold fs-5 dash-heading">
                                  No Data Found.
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {/* <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                        <div className="dataTables_info"></div>
                        <div
                          className="dataTables_paginate paging_simple_numbers mb-0"
                          id="application-tbl1_paginate"
                        >
                          <Pagination
                            color="primary"
                            count={Math.ceil(total / 10)}
                            onChange={handleChange}
                          />
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClaimBonus;
