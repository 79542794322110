import React from 'react'
import { FaLongArrowAltLeft } from "react-icons/fa";
import { Link } from 'react-router-dom';

export const Disclaimer = () => {
    return (
        <div>
            <div className='container'>
                <div className='row'>
                    <div class="elementor-widget-container mt-5">

                        <h2 className='text-white'> <Link to="/"><FaLongArrowAltLeft /> </Link>
                        </h2>
                        <h2 className='text-center text-white '> ### DSC Blockchain Disclaimer ###
                        </h2>
                        <p class="elementor-heading-title elementor-size-default">The content presented on this website is for informational purposes only and does not constitute an offer to sell or solicit an offer to buy DSC tokens or any other financial instruments. The information herein reflects the opinions of the DSC team and is subject to change without prior notice.
                        </p>
                        <p class="elementor-heading-title elementor-size-default">DSC Blockchain and its affiliates make no guarantees regarding the accuracy, reliability, or completeness of the information provided. Readers are encouraged to conduct their own research and seek advice from financial, legal, or other professional advisors before making any investment decisions based on the content provided.

                        </p>

                        <p class="elementor-heading-title elementor-size-default">Investing in blockchain technology and cryptocurrencies involves substantial risks, including the potential loss of the entire invested amount. DSC Blockchain and its associated entities disclaim any liability for direct, indirect, incidental, or consequential damages arising from the use or inability to use the information provided on this website.


                        </p>

                        <p class="elementor-heading-title elementor-size-default">DSC Blockchain is not responsible for the loss of assets, including tokens, and does not offer financial security or advice. Token transfers and transactions are conducted at the user’s own risk, and DSC Blockchain is not liable for any issues related to token transfers or loss of assets.



                        </p>

                        <p class="elementor-heading-title elementor-size-default">By accessing this website, you agree to indemnify DSC Blockchain against any legal or financial repercussions resulting from reliance on the information presented.



                        </p>

                        <p class="elementor-heading-title fw-bolder elementor-size-default">**Note:** DSC Blockchain and its related projects are continually evolving. This website may be updated as the project progresses, and readers should revisit the website regularly to stay informed of the latest developments.




                        </p>
                    </div>

                </div>
            </div>
        </div>
    )
}
