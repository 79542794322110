import React, { useState, useEffect } from "react";

import ConnectButtons from "../Button/ConnectButton";

import logo from "../../../new.png";
import { Link } from "react-router-dom";


const NavMain = () => {
  const [headerFix, setheaderFix] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setheaderFix(window.scrollY > 50);
    });
  }, []);
  
  return (
    <div className={`newheader ${headerFix ? "is-fixed" : ""}`}>
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">

              <div className="logo-header">
                <Link to="/" className="logo">
                  <img
                    src={logo}
                    alt=""
                    className=" mCS_img_loaded"
                    style={{ height: '45px' }}
                  />
                </Link>

              </div>
            </div>

            <div className="navbar-nav header-right">
              <div className="nav-item d-flex align-items-center">
                <div className="input-group">

                  <ConnectButtons />
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default NavMain;
