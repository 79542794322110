import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import { Pagination } from "@mui/material";
import { getDailyRoi, getStakedIncome, getStakingHistory } from "../../../helpers/api";
import { useSelector } from "react-redux";
import { Loader } from "../Dashboard/Loader";
import Countdown, { zeroPad } from "react-countdown";

import "bootstrap-daterangepicker/daterangepicker.css";

const RoiBonus = (props) => {
    const { walletAddress } = useSelector((action) => {
        return action.auth;
    });

    const [apidata, setApidata] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);



    useEffect(() => {
        getDailyRoi(walletAddress).then((res) => {
            console.log(res, "res:::dkdkdd")
            setApidata(res?.data);
            setLoading(true);
        });
    }, [walletAddress]);
    return (
        <div>
            <div className="row">
                <div className="col-xxl-12">
                    <div className="card">
                        {!loading ? (
                            <Loader />
                        ) : (
                            <>
                                <div className="card-header border-0 pb-2 flex-wrap">
                                    <h4 className="heading me-2">My Roi Bonus History</h4>
                                </div>
                                <div className="card-body pt-2">
                                    <div
                                        id="history_wrapper"
                                        className="table-responsive dataTablehistory"
                                    >
                                        <div className="dataTables_wrapper no-footer">
                                            <table
                                                id="example"
                                                className="table shadow-hover dataTable display"
                                                style={{ minWidth: "845px" }}
                                            >
                                                <thead>
                                                    <tr>


                                                        <th className="text-white">Staking Value (USDT)</th>
                                                        <th className="text-white">Roi per day (USDT)</th>
                                                        <th className="text-white"> Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {apidata?.length > 0 ? (
                                                        apidata?.map((e, index) => (
                                                            <tr key={index}>


                                                                <td>{(e?.amount)}</td>
                                                                <td>{(e?.income)?.toFixed(3)}</td>



                                                                <td>
                                                                    {moment(
                                                                        (e.createdAt)
                                                                    ).fromNow()}
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan={7}>
                                                                <div className="text-center  mt-4 mb-2 fw-bold fs-5 dash-heading">
                                                                    No Data Found.
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                            {/* <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                        <div className="dataTables_info"></div>
                        <div
                          className="dataTables_paginate paging_simple_numbers mb-0"
                          id="application-tbl1_paginate"
                        >
                          <Pagination
                            color="primary"
                            count={Math.ceil(total / 10)}
                            onChange={handleChange}
                          />
                        </div>
                      </div> */}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RoiBonus;
