import React, { useEffect, useState } from 'react'
import Marquee from "react-fast-marquee";
import { Link } from 'react-router-dom';
import Typewriter from 'typewriter-effect';
import ScrollToTop from "react-scroll-to-top";
import { getTokenPrice } from './helpers/getWeb3';

export const HomeMain = () => {
    const [menuVisible, setMenuVisible] = useState(false)
    const [currentPrice, setCurrentPrice] = useState(0)

    function toggleMenu() {
        setMenuVisible(!menuVisible)
    }

    useEffect(() => {
        getTokenPrice().then(setCurrentPrice);
    }, [])
    return (
        <div className={`${menuVisible ? 'mobile-menu-visible' : null}`}>
            <ScrollToTop smooth color="#fff" style={{ zIndex: 1000 }} />

            <div data-elementor-type="wp-post" data-elementor-id="121" class="elementor elementor-121">
                <div class="elementor-element elementor-element-aa37635 e-con-full e-flex e-con e-parent" data-id="aa37635" data-element_type="container" data-core-v316-plus="true">
                    <div class="elementor-element elementor-element-8e54fb7 elementor-widget elementor-widget-tg-header" data-id="8e54fb7" data-element_type="widget" data-widget_type="tg-header.default">
                        <div class="elementor-widget-container">
                            <header id="header" class="header-layout1">
                                <div id="sticky-header" class="menu-area transparent-header">
                                    <div class="container custom-container">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="menu-wrap">
                                                    <nav class="menu-nav">
                                                        <div class="logo">
                                                            <Link to="/">
                                                                <img src="./new.png" alt="IKO" className='logotext' />
                                                            </Link>
                                                        </div>
                                                        <div class="navbar-wrap main-menu m-auto d-none d-lg-flex">
                                                            <ul id="menu-1-8e54fb7" class="navigation"><li id="menu-item-1270" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-144 current_page_item menu-item-1270"><a href="" aria-current="page">Home</a></li>
                                                                <li id="menu-item-1274" class="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-1274"><a href="#why-blockchain" aria-current="page">About</a></li>
                                                                <li id="menu-item-1275" class="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-1275"><a href="#features" aria-current="page">Features</a></li>

                                                                <li id="menu-item-1272" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1272"><a href="#faq">faq</a></li>
                                                            </ul>                                        </div>
                                                        <div class="header-action">
                                                            <ul class="list-wrap">
                                                                <li class="header-login">
                                                                    <Link class="btn2 " to="/login">LOGIN</Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div class="mobile-nav-toggler" onClick={toggleMenu}><i class="fas fa-bars"></i></div>
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </header>

                            <div class="mobile-menu">
                                <nav class="menu-box">
                                    <div class="close-btn" onClick={toggleMenu}><i class="fas fa-times"></i></div>
                                    <div class="nav-logo mobile-logo">
                                        <Link to=''>
                                            <img src="./new.png" alt="IKO" className='logotext' />
                                        </Link>
                                    </div>
                                    <div class="menu-outer">


                                        <ul id="menu-1-8e54fb7" class="navigation"><li id="menu-item-1270" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-144 current_page_item menu-item-1270" onClick={toggleMenu}><a href="" aria-current="page">Home</a></li>
                                            <li id="menu-item-1275" class="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-1275" onClick={toggleMenu}><a href="#features" aria-current="page">About</a></li>
                                            <li id="menu-item-1274" class="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-1274" onClick={toggleMenu}><a href="#why-blockchain" aria-current="page">Features</a></li>
                                            <li id="menu-item-1274" class="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-1274" onClick={toggleMenu}><a href="#faq" aria-current="page">Faq</a></li>

                                            <li id="menu-item-1272" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1272 block" onClick={toggleMenu}><Link to="/register">Register</Link></li>

                                            <li id="menu-item-1272" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1272 block" onClick={toggleMenu}><Link to="/login">Login</Link></li>

                                        </ul>
                                    </div>
                                    <div class="social-links">
                                        <ul class="clearfix list-wrap">
                                            <li>
                                                <a href="#" target="_blank">
                                                    <i aria-hidden="true" class="fab fa-facebook-f"></i>                            </a>
                                            </li>
                                            <li>
                                                <a href="https://x.com/DSC_Blockchain" target="_blank">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M9.52219 6.77491L15.4786 0H14.0671L8.89518 5.88256L4.76438 0H0L6.24657 8.89547L0 16H1.41155L6.87322 9.78779L11.2356 16H16L9.52219 6.77491ZM7.58888 8.97384L6.95597 8.08805L1.92015 1.03974H4.08821L8.15218 6.72796L8.78508 7.61374L14.0677 15.0076H11.8997L7.58888 8.97384Z" fill="currentColor"></path></svg>                            </a>
                                            </li>
                                            <li>
                                                <a href="https://www.instagram.com/dscblockchain?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank">
                                                    <i aria-hidden="true" class="fab fa-instagram"></i>                            </a>
                                            </li>
                                            <li>
                                                <a href="https://t.me/dscblockchainsupport" target="_blank">
                                                    <i aria-hidden="true" class="fab fa-telegram-plane"></i>                            </a>
                                            </li>

                                        </ul>
                                    </div>
                                </nav>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <main class="main-area">
                <div data-elementor-type="wp-page" data-elementor-id="144" class="elementor elementor-144">
                    <div class="elementor-element elementor-element-035b06e e-con-full e-flex e-con e-parent" data-id="035b06e"
                        data-element_type="container" data-core-v316-plus="true">
                        <div class="elementor-element elementor-element-0eb8845 elementor-widget elementor-widget-tg-gradient"
                            data-id="0eb8845" data-element_type="widget" data-widget_type="tg-gradient.default">
                            <div class="elementor-widget-container">


                                <div class="tg-bg-gradient d-block purple-bg-gradient"></div>


                            </div>
                        </div>
                        <div class="elementor-element elementor-element-f7ee2de position-static elementor-widget elementor-widget-tg-gradient"
                            data-id="f7ee2de" data-element_type="widget" data-widget_type="tg-gradient.default">
                            <div class="elementor-widget-container">


                                <div class="tg-bg-gradient d-block hero-gradient-ball alltuchtopdown"></div>


                            </div>
                        </div>
                        <div class="elementor-element elementor-element-e38eacf position-static elementor-widget elementor-widget-tg-gradient"
                            data-id="e38eacf" data-element_type="widget" data-widget_type="tg-gradient.default">
                            <div class="elementor-widget-container">


                                <div class="tg-bg-gradient d-block green-bg-gradient"></div>


                            </div>
                        </div>
                        <div class="elementor-element elementor-element-9af2a27 elementor-widget elementor-widget-html"
                            data-id="9af2a27" data-element_type="widget" data-widget_type="html.default">
                            <div class="elementor-widget-container">
                                <div class="ripple-shape2">
                                    <span class="ripple-11"></span>
                                    <span class="ripple-12"></span>
                                    <span class="ripple-13"></span>
                                    <span class="ripple-14"></span>
                                    <span class="ripple-15"></span>
                                </div>
                            </div>
                        </div>
                        <div class="elementor-element elementor-element-4a35d6b e-flex e-con-boxed e-con e-child"
                            data-id="4a35d6b" data-element_type="container">
                            <div class="e-con-inner">
                                {/* <div class="elementor-element elementor-element-fbbe53e elementor-widget elementor-widget-tg-heading"
                                    data-id="fbbe53e" data-element_type="widget" data-widget_type="tg-heading.default">
                                    <div class="elementor-widget-container">


                                        <h2 class="title gradient-title">100%</h2>

                                    </div>
                                </div> */}
                                <div class="elementor-element elementor-element-dc7cfdb bitcoin-icon-position elementor-widget elementor-widget-heading"
                                    data-id="dc7cfdb" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">

                                        <h2 class="elementor-heading-title elementor-size-default"><Typewriter
                                            options={{
                                                strings: ['Decentralized', , 'DAO Driven', 'Safe & Secure', 'Cost Effective'],
                                                autoStart: true,
                                                loop: true,
                                            }}
                                        /></h2>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-e05638a e-con-full e-flex e-con e-child"
                                    data-id="e05638a" data-element_type="container">
                                    <div class="elementor-element elementor-element-d572db2 e-con-full e-flex e-con e-child"
                                        data-id="d572db2" data-element_type="container"
                                        data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}">
                                        <div class="elementor-element elementor-element-8ab7883 elementor-widget elementor-widget-heading"
                                            data-id="8ab7883" data-element_type="widget" data-widget_type="heading.default">
                                            <div class="elementor-widget-container">
                                                <h2 class="elementor-heading-title elementor-size-default">Safe & Secure Technology</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-element elementor-element-1a1d570 e-con-full e-flex e-con e-child"
                                        data-id="1a1d570" data-element_type="container">
                                        <div class="elementor-element elementor-element-54af084 elementor-widget elementor-widget-heading"
                                            data-id="54af084" data-element_type="widget" data-widget_type="heading.default">
                                            <div class="elementor-widget-container">
                                                <p class="elementor-heading-title elementor-size-default"><p class="elementor-heading-title elementor-size-default">DSC-LaB is a 100% decentralized digital DAO-Driven Community Build up platform based on blockchain technology and secured by cryptograph & Smart-Contarcts. The DSC-LaB ecosystem is one of the safest, tailoring financial and Learning & Earning opportunities to individual interests with De-Fi Ecosystem.</p>



                                                </p>
                                            </div>
                                        </div>
                                        <div class="elementor-element elementor-element-fa06e25 elementor-widget elementor-widget-tg-btn"
                                            data-id="fa06e25" data-element_type="widget" data-widget_type="tg-btn.default">
                                            <div class="elementor-widget-container">


                                                <a href="/login" target="_self" rel="nofollow"
                                                    class="btn btn2 show-arrow btnnew">
                                                    Plan PDF </a>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="e-con-inner">
                        <div class="elementor-element elementor-element-1db4ef1 elementor-widget elementor-widget-heading"
                            data-id="1db4ef1" data-element_type="widget" data-widget_type="heading.default">
                            <div class="elementor-widget-container">
                                <h1 class="elementor-heading-title elementor-size-default mt-3">Current Price</h1>
                            </div>
                        </div>



                        <div class="col-12">
                            <div class="text-center  mb-5">
                                <h3 className='text-warning  mb-5'>
                                    1 DSC = $ {(1 / currentPrice)?.toFixed(6)}  <img src="https://bscscan.com/token/images/busdt_32.png" alt="" className='mb-1' style={{ height: 25 }} />

                                </h3>
                            </div>


                        </div>
                    </div>


                    <div class="elementor-element elementor-element-c9d2a78 e-flex e-con-boxed e-con e-parent" data-id="c9d2a78"
                        data-element_type="container" data-core-v316-plus="true">
                        <div class="e-con-inner">
                            <div class="elementor-element elementor-element-1db4ef1 elementor-widget elementor-widget-heading"
                                data-id="1db4ef1" data-element_type="widget" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h2 class="elementor-heading-title elementor-size-default">Backed By Leading Blockchain
                                        Investors And Founders</h2>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-9e42df5 elementor-widget elementor-widget-brand"
                                data-id="9e42df5" data-element_type="widget" data-widget_type="brand.default">
                                <div class="elementor-widget-container">


                                    <div class="brand-item-wrap2 style2">
                                        <div class="row g-0 brand-active2">
                                            <Marquee>

                                                <div class="col-12">
                                                    <div class="brand-item p-3">
                                                        <a href="#"><img
                                                            src="./Layer 1 (1).png" alt="" style={{ height: '60px' }} /></a>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="brand-item p-3">
                                                        <a href="#"><img
                                                            src="wp-content/uploads/2024/logo-metamask (2).png" alt="" style={{ height: '60px' }} /></a>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="brand-item p-3 " >
                                                        <a href="#"><img
                                                            src="wp-content/uploads/2024/logo-w.7f84b51 (1).png" alt="" style={{ height: '60px' }} /></a>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="brand-item p-3">
                                                        <a href="#"><img
                                                            src="https://www.safepal.com/assets/img/logo-light.svg" alt="" style={{ height: '60px' }} /></a>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="brand-item p-3">
                                                        <a href="#"><img
                                                            src="wp-content/uploads/2024/logo-dim.svg" alt="" style={{ height: '60px' }} /></a>
                                                    </div>
                                                </div>
                                            </Marquee>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="elementor-element elementor-element-209c9fd e-flex e-con-boxed e-con e-parent" data-id="209c9fd"
                        data-element_type="container" id="why-blockchain"
                        data-settings="{&quot;background_background&quot;:&quot;classic&quot;}" data-core-v316-plus="true">
                        <div class="e-con-inner">
                            <div class="elementor-element elementor-element-e47cb87 elementor-absolute elementor-widget elementor-widget-genix-image"
                                data-id="e47cb87" data-element_type="widget"
                                data-settings="{&quot;_position&quot;:&quot;absolute&quot;}"
                                data-widget_type="genix-image.default">
                                <div class="elementor-widget-container">

                                    <div class="feature-area-shape">
                                        <img decoding="async" class="feature-shape2-1 alltuchtopdown"
                                            src="wp-content/uploads/2024/04/feature-shape-11.png" alt="" />
                                        <img decoding="async" class="feature-shape2-2 alltuchtopdown"
                                            src="./logodsc.png" alt="" style={{ height: '40px' }} />
                                        <img decoding="async" class="feature-shape2-3 leftToRight"
                                            src="wp-content/uploads/2024/04/feature-shape-13.png" alt="" />
                                    </div>

                                </div>
                            </div>
                            <div class="elementor-element elementor-element-fe23971 elementor-absolute elementor-widget elementor-widget-image"
                                data-id="fe23971" data-element_type="widget"
                                data-settings="{&quot;_position&quot;:&quot;absolute&quot;}" data-widget_type="image.default">
                                <div class="elementor-widget-container">
                                    <img fetchpriority="high" decoding="async" width="363" height="435"
                                        src="wp-content/uploads/2024/04/cirlce-radius.png"
                                        class="attachment-full size-full wp-image-974" alt=""
                                        srcset="https://iko.themegenix.net/blockchain/wp-content/uploads/2024/04/cirlce-radius.png 363w, https://iko.themegenix.net/blockchain/wp-content/uploads/2024/04/cirlce-radius-250x300.png 250w"
                                        sizes="(max-width: 363px) 100vw, 363px" />
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-b82ac03 e-flex e-con-boxed e-con e-child"
                                data-id="b82ac03" data-element_type="container">
                                <div class="e-con-inner">
                                    <div class="elementor-element elementor-element-04db060 elementor-widget elementor-widget-tg-heading"
                                        data-id="04db060" data-element_type="widget" data-widget_type="tg-heading.default">
                                        <div class="elementor-widget-container">


                                            <h2 class="title gradient-title">ABOUT DSC-LaB</h2>

                                        </div>
                                    </div>
                                    <div class="elementor-element elementor-element-c4a8331 elementor-widget elementor-widget-heading"
                                        data-id="c4a8331" data-element_type="widget" data-widget_type="heading.default">
                                        <div class="elementor-widget-container">
                                            <h2 class="elementor-heading-title elementor-size-default">What Exactly Is DSC-LaB ?</h2>
                                        </div>
                                    </div>
                                    <div class="elementor-element elementor-element-d9b0b6c elementor-widget elementor-widget-heading"
                                        data-id="d9b0b6c" data-element_type="widget" data-widget_type="heading.default">
                                        <div class="elementor-widget-container">
                                            <p class="elementor-heading-title elementor-size-default">DSC-LaB is a global Cryptocurrency Ecosystem whose members assist one another in achieving Learning With Crypto Earning through the use of our tools and algorithm of instant profit distribution on smart contracts. The DSC-LaB system is completely decentralised and belongs to our community, which means it is transparent, secure, and resistant to outside influences.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-a328fa0 e-grid e-con-full e-con e-child"
                                data-id="a328fa0" data-element_type="container">
                                <div class="elementor-element elementor-element-5f4d013 e-con-full e-flex e-con e-child"
                                    data-id="5f4d013" data-element_type="container"
                                    data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                    <div class="elementor-element elementor-element-6cf0f13 e-con-full e-flex e-con e-child"
                                        data-id="6cf0f13" data-element_type="container">
                                        <div class="elementor-element elementor-element-db631c7 elementor-widget elementor-widget-heading"
                                            data-id="db631c7" data-element_type="widget" data-widget_type="heading.default">
                                            <div class="elementor-widget-container">
                                                <h2 class="elementor-heading-title elementor-size-default">DAO-Driven</h2>
                                            </div>
                                        </div>
                                        <div class="elementor-element elementor-element-aa8a993 elementor-widget elementor-widget-heading"
                                            data-id="aa8a993" data-element_type="widget" data-widget_type="heading.default">
                                            <div class="elementor-widget-container">
                                                <p class="elementor-heading-title elementor-size-default">Instead Of single Owner Or Creator Group Of Community which Plays A Major Role in Community Build-up And Participating Every events organise for Scale up Ecosystem and Boundaries. Such Top 25 Community Members Can Vote and Conclude to Decision Required  to take in Development of whole Ecosystem Journey Of DSC Token To DSC Coin with layer one Blockchain, DEX, Multi-Bridge, De-Fi Wallet and so on......

                                                </p>
                                            </div>
                                        </div>
                                        {/* <div class="elementor-element elementor-element-58eb018 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                                            data-id="58eb018" data-element_type="widget" data-widget_type="icon-list.default">
                                            <div class="elementor-widget-container">
                                                <link rel="stylesheet"
                                                    href="wp-content/uploads/elementor/css/custom-widget-icon-list.minc632.css?ver=1712066095" />
                                                <ul class="elementor-icon-list-items">
                                                    <li class="elementor-icon-list-item">
                                                        <span class="elementor-icon-list-icon">
                                                            <i aria-hidden="true" class="fas fa-circle"></i> </span>
                                                        <span class="elementor-icon-list-text">Physical Flexibility.</span>
                                                    </li>
                                                    <li class="elementor-icon-list-item">
                                                        <span class="elementor-icon-list-icon">
                                                            <i aria-hidden="true" class="fas fa-circle"></i> </span>
                                                        <span class="elementor-icon-list-text">Organizational Flexibility
                                                        </span>
                                                    </li>
                                                    <li class="elementor-icon-list-item">
                                                        <span class="elementor-icon-list-icon">
                                                            <i aria-hidden="true" class="fas fa-circle"></i> </span>
                                                        <span class="elementor-icon-list-text">Technical Flexibility</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div class="elementor-element elementor-element-14d1b6d alltuchtopdown e-transform elementor-widget elementor-widget-image"
                                        data-id="14d1b6d" data-element_type="widget"
                                        data-settings="{&quot;_transform_translateX_effect&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-90,&quot;sizes&quot;:[]},&quot;_transform_translateX_effect_laptop&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;_transform_translateX_effect_tablet&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_translateX_effect_mobile&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_translateY_effect&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_translateY_effect_laptop&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_translateY_effect_tablet&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_translateY_effect_mobile&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]}}"
                                        data-widget_type="image.default">
                                        <div class="elementor-widget-container">
                                            <img decoding="async" width="536" height="286"
                                                src="wp-content/uploads/2024/04/feature-card-thumb-1.png"
                                                class="attachment-full size-full wp-image-944" alt=""
                                                // srcset="https://iko.themegenix.net/blockchain/wp-content/uploads/2024/04/feature-card-thumb-1.png 536w, https://iko.themegenix.net/blockchain/wp-content/uploads/2024/04/feature-card-thumb-1-300x160.png 300w"
                                                sizes="(max-width: 536px) 100vw, 536px" />
                                        </div>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-75b3ac5 e-con-full e-flex e-con e-child"
                                    data-id="75b3ac5" data-element_type="container">
                                    <div class="elementor-element elementor-element-046f9bd e-con-full e-flex e-con e-child"
                                        data-id="046f9bd" data-element_type="container"
                                        data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                        <div class="elementor-element elementor-element-2e99aa3 e-con-full e-flex e-con e-child"
                                            data-id="2e99aa3" data-element_type="container">
                                            <div class="elementor-element elementor-element-81e0203 elementor-widget elementor-widget-heading"
                                                data-id="81e0203" data-element_type="widget" data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default">Smart Contracts</h2>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-b0ad7c3 elementor-widget elementor-widget-heading"
                                                data-id="b0ad7c3" data-element_type="widget" data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <p class="elementor-heading-title elementor-size-default">A smart contract is a self-executing program on a blockchain, like Ethereum, Binance, that automatically enforces the terms of an agreement when conditions are met. They eliminate intermediaries, increase transparency, and ensure trust through immutable code. Smart contracts are used in areas like finance, supply chains, and real estate. Their automation and security make them a revolutionary technology for decentralized applications.</p>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="elementor-element elementor-element-d0c5623 elementor-widget elementor-widget-image"
                                            data-id="d0c5623" data-element_type="widget" data-widget_type="image.default">
                                            <div class="elementor-widget-container">
                                                <img decoding="async" width="254" height="245"
                                                    src="wp-content/uploads/2024/04/feature-card-thumb-2.png"
                                                    class="attachment-full size-full wp-image-952" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-element elementor-element-1576866 e-con-full e-flex e-con e-child"
                                        data-id="1576866" data-element_type="container"
                                        data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                        <div class="elementor-element elementor-element-d977c5c e-con-full e-flex e-con e-child"
                                            data-id="d977c5c" data-element_type="container">
                                            <div class="elementor-element elementor-element-0b41698 elementor-widget elementor-widget-heading"
                                                data-id="0b41698" data-element_type="widget" data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default">Secure & Safe
                                                    </h2>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-b75024c elementor-widget elementor-widget-heading"
                                                data-id="b75024c" data-element_type="widget" data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <p class="elementor-heading-title elementor-size-default">Secure and safe have similar definitions when used as adjectives (words that describe or modify a noun).

                                                    </p>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-a4dfa23 elementor-widget elementor-widget-heading"
                                                data-id="a4dfa23" data-element_type="widget" data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <p class="elementor-heading-title elementor-size-default">Safe: “Free from harm or risk” Secure: “Free from danger”

                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elementor-element elementor-element-6f6aeef elementor-widget elementor-widget-image"
                                            data-id="6f6aeef" data-element_type="widget" data-widget_type="image.default">
                                            <div class="elementor-widget-container">
                                                <img loading="lazy" decoding="async" width="300" height="298"
                                                    src="wp-content/uploads/2024/04/feature-card-thumb-3.png"
                                                    class="attachment-full size-full wp-image-953" alt=""
                                                    srcset="https://iko.themegenix.net/blockchain/wp-content/uploads/2024/04/feature-card-thumb-3.png 300w, https://iko.themegenix.net/blockchain/wp-content/uploads/2024/04/feature-card-thumb-3-150x150.png 150w"
                                                    sizes="(max-width: 300px) 100vw, 300px" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="elementor-element elementor-element-35a2f6f e-flex e-con-boxed e-con e-parent" data-id="35a2f6f"
                        data-element_type="container" id="features"
                        data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}" data-core-v316-plus="true">
                        <div class="e-con-inner">
                            <div class="elementor-element elementor-element-5f80d9a elementor-absolute alltuchtopdown elementor-widget elementor-widget-image"
                                data-id="5f80d9a" data-element_type="widget"
                                data-settings="{&quot;_position&quot;:&quot;absolute&quot;}" data-widget_type="image.default">
                                <div class="elementor-widget-container">
                                    <img loading="lazy" decoding="async" width="81" height="247"
                                        src="wp-content/uploads/2024/04/left-vector.png"
                                        class="attachment-full size-full wp-image-1009" alt="" />
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-658be2a elementor-absolute alltuchtopdown elementor-widget elementor-widget-image"
                                data-id="658be2a" data-element_type="widget"
                                data-settings="{&quot;_position&quot;:&quot;absolute&quot;}" data-widget_type="image.default">
                                <div class="elementor-widget-container">
                                    <img loading="lazy" decoding="async" width="67" height="202"
                                        src="wp-content/uploads/2024/04/right-vector.png"
                                        class="attachment-full size-full wp-image-1010" alt="" />
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-0198f33 e-con-full e-flex e-con e-child"
                                data-id="0198f33" data-element_type="container">
                                <div class="elementor-element elementor-element-92f0251 elementor-widget elementor-widget-image"
                                    data-id="92f0251" data-element_type="widget" data-widget_type="image.default">
                                    <div class="elementor-widget-container">
                                        <img loading="lazy" decoding="async" width="276" height="344"
                                            src="wp-content/uploads/2024/04/intro_1-1.png"
                                            class="attachment-full size-full wp-image-982" alt=""
                                            srcset="https://iko.themegenix.net/blockchain/wp-content/uploads/2024/04/intro_1-1.png 276w, https://iko.themegenix.net/blockchain/wp-content/uploads/2024/04/intro_1-1-241x300.png 241w"
                                            sizes="(max-width: 276px) 100vw, 276px" />
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-1d82943 elementor-absolute alltuchtopdown elementor-widget elementor-widget-image"
                                    data-id="1d82943" data-element_type="widget"
                                    data-settings="{&quot;_position&quot;:&quot;absolute&quot;}"
                                    data-widget_type="image.default">
                                    <div class="elementor-widget-container">
                                        <img loading="lazy" decoding="async" width="445" height="362"
                                            src="wp-content/uploads/2024/04/feature-glaxy.png"
                                            class="attachment-full size-full wp-image-983" alt=""
                                            srcset="https://iko.themegenix.net/blockchain/wp-content/uploads/2024/04/feature-glaxy.png 445w, https://iko.themegenix.net/blockchain/wp-content/uploads/2024/04/feature-glaxy-300x244.png 300w"
                                            sizes="(max-width: 445px) 100vw, 445px" />
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-861aebe elementor-absolute elementor-widget elementor-widget-image"
                                    data-id="861aebe" data-element_type="widget"
                                    data-settings="{&quot;_position&quot;:&quot;absolute&quot;}"
                                    data-widget_type="image.default">
                                    <div class="elementor-widget-container">
                                        <img loading="lazy" decoding="async" width="153" height="153"
                                            src="wp-content/uploads/2024/04/vector.png"
                                            class="attachment-full size-full wp-image-984" alt=""
                                            srcset="https://iko.themegenix.net/blockchain/wp-content/uploads/2024/04/vector.png 153w, https://iko.themegenix.net/blockchain/wp-content/uploads/2024/04/vector-150x150.png 150w"
                                            sizes="(max-width: 153px) 100vw, 153px" />
                                    </div>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-1893fc5 e-con-full e-flex e-con e-child"
                                data-id="1893fc5" data-element_type="container">
                                <div class="elementor-element elementor-element-13d0d8d elementor-widget elementor-widget-heading"
                                    data-id="13d0d8d" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <h2 class="elementor-heading-title elementor-size-default">FEATURES</h2>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-27f4ded elementor-widget elementor-widget-heading"
                                    data-id="27f4ded" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <h2 class="elementor-heading-title elementor-size-default">Blockchain spreads Trust
                                            everywhere</h2>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-f7a8db6 elementor-widget__width-initial elementor-widget elementor-widget-heading"
                                    data-id="f7a8db6" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <p class="elementor-heading-title elementor-size-default">DSC-LaB is world best advance digital Educational and Decentralized Referral program driven by smart contract based on blockchain technology.

                                            About DSC-LaB DSC-LaB is world best advance digital Educational and Decentralized Referral program driven by smart contract based on blockchain technology. Blockchain technology is an advanced database mechanism that allows transparent information sharing within a business network. A blockchain database stores data in blocks that are linked together in a chain. The data is chronologically consistent because you cannot delete or modify the chain without consensus from the network.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="elementor-element elementor-element-b249357 e-flex e-con-boxed e-con e-parent" data-id="b249357"
                        data-element_type="container" data-core-v316-plus="true">
                        <div class="e-con-inner">
                            <div class="elementor-element elementor-element-9916532 position-static elementor-widget elementor-widget-html"
                                data-id="9916532" data-element_type="widget" data-widget_type="html.default">
                                <div class="elementor-widget-container">
                                    <div class="feature-shape-4-1 spin"></div>
                                    <div class="feature-shape-4-2 alltuchtopdown"></div>
                                    <div class="feature-shape-4-3 alltuchtopdown"></div>
                                    <div class="feature-shape-4-4"></div>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-1e8544f e-con-full e-flex e-con e-child"
                                data-id="1e8544f" data-element_type="container">
                                <div class="elementor-element elementor-element-652ecd2 e-con-full e-flex e-con e-child"
                                    data-id="652ecd2" data-element_type="container">
                                    <div class="elementor-element elementor-element-57fac6f elementor-widget elementor-widget-tg-heading"
                                        data-id="57fac6f" data-element_type="widget" data-widget_type="tg-heading.default">
                                        <div class="elementor-widget-container">


                                            <h2 class="title gradient-title">Benefits</h2>

                                        </div>
                                    </div>
                                    <div class="elementor-element elementor-element-4c43919 elementor-widget elementor-widget-heading"
                                        data-id="4c43919" data-element_type="widget" data-widget_type="heading.default">
                                        <div class="elementor-widget-container">
                                            <h2 class="elementor-heading-title elementor-size-default">We Offer</h2>
                                        </div>
                                    </div>
                                    <div class="elementor-element elementor-element-da716e1 elementor-widget elementor-widget-heading"
                                        data-id="da716e1" data-element_type="widget" data-widget_type="heading.default">
                                        <div class="elementor-widget-container">
                                            <p class="elementor-heading-title elementor-size-default">Unlock the full potential of our product with our amazing features and top-notch.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-583b942 e-con-full e-flex e-con e-child"
                                    data-id="583b942" data-element_type="container">
                                    <div class="elementor-element elementor-element-5a58f54 e-grid e-con-full e-con e-child"
                                        data-id="5a58f54" data-element_type="container">
                                        <div class="elementor-element elementor-element-c61a9cd elementor-widget elementor-widget-heading"
                                            data-id="c61a9cd" data-element_type="widget" data-widget_type="heading.default">
                                            <div class="elementor-widget-container">
                                                <h2 class="elementor-heading-title elementor-size-default"><a href="#">Smart
                                                    contracts</a></h2>
                                            </div>
                                        </div>
                                        <div class="elementor-element elementor-element-75283a0 elementor-widget elementor-widget-heading"
                                            data-id="75283a0" data-element_type="widget" data-widget_type="heading.default">
                                            <div class="elementor-widget-container">
                                                <h2 class="elementor-heading-title elementor-size-default"><a href="#">Lightning Fast</a></h2>
                                            </div>
                                        </div>
                                        <div class="elementor-element elementor-element-fbed052 elementor-widget elementor-widget-heading"
                                            data-id="fbed052" data-element_type="widget" data-widget_type="heading.default">
                                            <div class="elementor-widget-container">
                                                <h2 class="elementor-heading-title elementor-size-default"><a href="#">Highly Secure

                                                </a></h2>
                                            </div>
                                        </div>
                                        <div class="elementor-element elementor-element-d53333e elementor-widget elementor-widget-heading"
                                            data-id="d53333e" data-element_type="widget" data-widget_type="heading.default">
                                            <div class="elementor-widget-container">
                                                <h2 class="elementor-heading-title elementor-size-default"><a
                                                    href="#">Robust & Resilient

                                                </a></h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-51acc00 e-con-full e-flex e-con e-child"
                                data-id="51acc00" data-element_type="container">
                                <div class="elementor-element elementor-element-16efc18 e-con-full e-flex e-con e-child"
                                    data-id="16efc18" data-element_type="container"
                                    data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}">
                                    <div class="elementor-element elementor-element-ce43b1d e-con-full e-flex e-con e-child"
                                        data-id="ce43b1d" data-element_type="container">
                                        <div class="elementor-element elementor-element-298ad1e alltuchtopdown elementor-widget elementor-widget-image"
                                            data-id="298ad1e" data-element_type="widget" data-widget_type="image.default">
                                            <div class="elementor-widget-container">
                                                <img loading="lazy" decoding="async" width="461" height="462"
                                                    src="wp-content/uploads/2024/04/BBBBB.png"
                                                    class="attachment-full size-full wp-image-1025" alt=""
                                                    //srcset="https://iko.themegenix.net/blockchain/wp-content/uploads/2024/04/bitcoin-img.png 461w, https://iko.themegenix.net/blockchain/wp-content/uploads/2024/04/bitcoin-img-300x300.png 300w, https://iko.themegenix.net/blockchain/wp-content/uploads/2024/04/bitcoin-img-150x150.png 150w, https://iko.themegenix.net/blockchain/wp-content/uploads/2024/04/bitcoin-img-350x350.png 350w, https://iko.themegenix.net/blockchain/wp-content/uploads/2024/04/bitcoin-img-460x460.png 460w"
                                                    sizes="(max-width: 461px) 100vw, 461px" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-element elementor-element-2441eb9 e-con-full e-flex e-con e-child"
                                        data-id="2441eb9" data-element_type="container">
                                        <div class="elementor-element elementor-element-6efdca0 elementor-widget elementor-widget-heading"
                                            data-id="6efdca0" data-element_type="widget" data-widget_type="heading.default">
                                            <div class="elementor-widget-container">
                                                <h2 class="elementor-heading-title elementor-size-default">Our Mission</h2>
                                            </div>
                                        </div>
                                        <div class="elementor-element elementor-element-6cdb28e elementor-widget elementor-widget-heading"
                                            data-id="6cdb28e" data-element_type="widget" data-widget_type="heading.default">
                                            <div class="elementor-widget-container">
                                                <h2 class="elementor-heading-title elementor-size-default">"Identifying and supporting promising startups.
                                                </h2>
                                            </div>
                                        </div>
                                        <div class="elementor-element elementor-element-cc11a85 elementor-widget elementor-widget-heading"
                                            data-id="cc11a85" data-element_type="widget" data-widget_type="heading.default">
                                            <div class="elementor-widget-container">
                                                <p class="elementor-heading-title elementor-size-default">Through strategic investments and hands-on guidance, we aim to accelerate the development and success of pioneering ideas that drive positive change globally.

                                                </p>
                                            </div>
                                        </div>
                                        <div class="elementor-element elementor-element-35e814c elementor-widget elementor-widget-tg-btn"
                                            data-id="35e814c" data-element_type="widget" data-widget_type="tg-btn.default">
                                            <div class="elementor-widget-container">


                                                <a href="/login" target="_self" rel="nofollow"
                                                    class="btn btn2 show-arrow btnnew">
                                                    Login </a>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="elementor-element elementor-element-b8413d2 e-flex e-con-boxed e-con e-parent" data-id="b8413d2"
                        data-element_type="container" data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}"
                        data-core-v316-plus="true">
                        <div class="e-con-inner">
                            <div class="elementor-element elementor-element-6e475f8 elementor-absolute alltuchtopdown elementor-widget elementor-widget-image"
                                data-id="6e475f8" data-element_type="widget"
                                data-settings="{&quot;_position&quot;:&quot;absolute&quot;}" data-widget_type="image.default">
                                <div class="elementor-widget-container">
                                    <img loading="lazy" decoding="async" width="256" height="167"
                                        src="wp-content/uploads/2024/04/line-shape.png"
                                        class="attachment-full size-full wp-image-1082" alt="" />
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-5a40551 elementor-absolute alltuchtopdown elementor-widget elementor-widget-image"
                                data-id="5a40551" data-element_type="widget"
                                data-settings="{&quot;_position&quot;:&quot;absolute&quot;}" data-widget_type="image.default">
                                <div class="elementor-widget-container">
                                    <img loading="lazy" decoding="async" width="82" height="82"
                                        src="wp-content/uploads/2024/04/circle001.png"
                                        class="attachment-full size-full wp-image-1102" alt="" />
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-d405f5e elementor-absolute leftToRight elementor-widget elementor-widget-image"
                                data-id="d405f5e" data-element_type="widget"
                                data-settings="{&quot;_position&quot;:&quot;absolute&quot;}" data-widget_type="image.default">
                                <div class="elementor-widget-container">
                                    <img loading="lazy" decoding="async" width="109" height="109"
                                        src="wp-content/uploads/2024/04/circle002.png"
                                        class="attachment-full size-full wp-image-1103" alt="" />
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-baa8431 e-con-full e-flex e-con e-child"
                                data-id="baa8431" data-element_type="container"
                                data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                <div class="elementor-element elementor-element-7f87821 e-con-full e-flex e-con e-child"
                                    data-id="7f87821" data-element_type="container">
                                    <div class="elementor-element elementor-element-1641128 elementor-widget elementor-widget-heading"
                                        data-id="1641128" data-element_type="widget" data-widget_type="heading.default">
                                        <div class="elementor-widget-container">
                                            <h2 class="elementor-heading-title elementor-size-default">TECHNOLOGY</h2>
                                        </div>
                                    </div>
                                    <div class="elementor-element elementor-element-2e83b30 elementor-widget elementor-widget-heading"
                                        data-id="2e83b30" data-element_type="widget" data-widget_type="heading.default">
                                        <div class="elementor-widget-container">
                                            <h2 class="elementor-heading-title elementor-size-default">How does DSC-LaB work for business?</h2>
                                        </div>
                                    </div>
                                    <div class="elementor-element elementor-element-361d4a1 elementor-widget__width-initial elementor-widget elementor-widget-heading"
                                        data-id="361d4a1" data-element_type="widget" data-widget_type="heading.default">
                                        <div class="elementor-widget-container">
                                            <p class="elementor-heading-title elementor-size-default">DSC-LaB invests in businesses and projects that align with Meta's strategic goals, particularly in areas like virtual reality (VR), augmented reality (AR), artificial intelligence (AI), and blockchain technologies..</p>
                                        </div>
                                    </div>
                                    <div class="elementor-element elementor-element-5d4ca75 elementor-widget elementor-widget-tg-btn"
                                        data-id="5d4ca75" data-element_type="widget" data-widget_type="tg-btn.default">
                                        <div class="elementor-widget-container">


                                            <a href="/login" target="_self" rel="nofollow"
                                                class="btn btn2 show-arrow btnnew">
                                                Get Started </a>

                                        </div>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-a1db687 e-con-full e-flex e-con e-child"
                                    data-id="a1db687" data-element_type="container">
                                    <div class="elementor-element elementor-element-28f8e8e elementor-widget elementor-widget-tg-popup-video"
                                        data-id="28f8e8e" data-element_type="widget" data-widget_type="tg-popup-video.default">
                                        <div class="elementor-widget-container">

                                            <a href="" class="play-btn popup-video">
                                                <i>
                                                    <svg width="22" height="23" viewBox="0 0 22 23" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M1.66602 11.6553V7.50193C1.66602 2.34526 5.31768 0.233597 9.78602 2.81193L13.391 4.8886L16.996 6.96526C21.4643 9.5436 21.4643 13.7669 16.996 16.3453L13.391 18.4219L9.78602 20.4986C5.31768 23.0769 1.66602 20.9653 1.66602 15.8086V11.6553Z"
                                                            stroke="white" stroke-width="2" stroke-miterlimit="10"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </i>
                                            </a>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="elementor-element elementor-element-3ecec9d e-flex e-con-boxed e-con e-parent" data-id="3ecec9d"
                        data-element_type="container" data-core-v316-plus="true">
                        <div class="e-con-inner" >
                            <div class="elementor-element elementor-element-39547d9 position-static elementor-widget elementor-widget-html"
                                data-id="39547d9" data-element_type="widget" data-widget_type="html.default" id="faq">
                                <div class="elementor-widget-container">
                                    <div class="faq-2-shape-1"></div>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-70e30b1 e-flex e-con-boxed e-con e-child"
                                data-id="70e30b1" data-element_type="container">
                                <div class="e-con-inner">
                                    <div class="elementor-element elementor-element-272d0d2 elementor-widget elementor-widget-tg-heading"
                                        data-id="272d0d2" data-element_type="widget" data-widget_type="tg-heading.default">
                                        <div class="elementor-widget-container">


                                            <h2 class="title gradient-title">Frequently Asked Questions</h2>

                                        </div>
                                    </div>
                                    <div class="elementor-element elementor-element-2f68d8f elementor-widget elementor-widget-heading"
                                        data-id="2f68d8f" data-element_type="widget" data-widget_type="heading.default">
                                        <div class="elementor-widget-container">
                                            <h2 class="elementor-heading-title elementor-size-default">Popular questions about
                                                DSC-LaB</h2>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="elementor-element elementor-element-69471f4 e-flex e-con-boxed e-con e-child"
                                data-id="69471f4" data-element_type="container">
                                <div class="e-con-inner">
                                    <div class="elementor-element elementor-element-b9173be elementor-widget elementor-widget-genix-faq"
                                        data-id="b9173be" data-element_type="widget" data-widget_type="genix-faq.default">
                                        <div class="elementor-widget-container">



                                            <div class="accordion-area accordion" id="blockchainAccordion">
                                                <div class="accordion-card style2 active-item">
                                                    <div class="accordion-header" id="headingOne-0">
                                                        <button class="accordion-button " type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#collapseOne-0"
                                                            aria-expanded="true" aria-controls="collapseOne-0">
                                                            What is Blockchain ? </button>
                                                    </div>
                                                    <div id="collapseOne-0" class="accordion-collapse collapse show"
                                                        aria-labelledby="headingOne-0" data-bs-parent="#blockchainAccordion">
                                                        <div class="accordion-body">
                                                            <p class="faq-text">A blockchain is a distributed database or ledger that is shared among the nodes of a computer network. As a database, a blockchain stores information electronically in digital format. Blockchain are best known for their crucial role in cryptocurrency systems, such as Bitcoin, for maintaining a secure and decentralized record of transactions. The innovation with a blockchain is that it guarantees the fidelity and security of a record of data and generates trust without the need for a trusted third party.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="accordion-card style2 ">
                                                    <div class="accordion-header" id="headingOne-1">
                                                        <button class="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#collapseOne-1"
                                                            aria-expanded="true" aria-controls="collapseOne-1">
                                                            What is Decentralization ? </button>
                                                    </div>
                                                    <div id="collapseOne-1" class="accordion-collapse collapse "
                                                        aria-labelledby="headingOne-1" data-bs-parent="#blockchainAccordion">
                                                        <div class="accordion-body">
                                                            <p class="faq-text">It is a system in which there are no admins, there is no single server or system monitoring, project management. The creators of the platform are the same project participants like you.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="accordion-card style2 ">
                                                    <div class="accordion-header" id="headingOne-2">
                                                        <button class="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#collapseOne-2"
                                                            aria-expanded="true" aria-controls="collapseOne-2">
                                                            What is Smart Contract ? </button>
                                                    </div>
                                                    <div id="collapseOne-2" class="accordion-collapse collapse "
                                                        aria-labelledby="headingOne-2" data-bs-parent="#blockchainAccordion">
                                                        <div class="accordion-body">
                                                            <p class="faq-text">Smart Contracts, Like Cryptocurrencies, Are Decentralized. They Work Strictly According To The Underlying Program, Without The Possibility Of Subsequently Chan- Ging The Defined Execution. The Code That Contains All The Logic Of The Contract Expiration Is On The Blockchain And All Calculations Are Provided By Millions Of Computers Around The World. This Ensures That There Is No Risk Of Hacking Into The Smart Contract And Stopping The Project.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="accordion-card style2 ">
                                                    <div class="accordion-header" id="headingOne-4">
                                                        <button class="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#collapseOne-4"
                                                            aria-expanded="true" aria-controls="collapseOne-4">
                                                            WHAT IS DSC-LaB? </button>
                                                    </div>
                                                    <div id="collapseOne-4" class="accordion-collapse collapse "
                                                        aria-labelledby="headingOne-4" data-bs-parent="#blockchainAccordion">
                                                        <div class="accordion-body">
                                                            <p class="faq-text">DSC-LaB is world best advance digital Educational and Decentralized Referral program driven by smart contract based on blockchain technology. Therefore, the goal of the referral programme is to improve people's financial situations and develop their skill sets so that they can achieve their goals and live a life of happiness.

                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="elementor-element elementor-element-e17943d e-flex e-con-boxed e-con e-parent" data-id="e17943d"
                        data-element_type="container" data-core-v316-plus="true">
                        <div class="e-con-inner">
                            <div class="elementor-element elementor-element-dbad8e1 e-con-full e-flex e-con e-child"
                                data-id="dbad8e1" data-element_type="container"
                                data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}">
                                <div class="elementor-element elementor-element-666fae3 e-con-full e-flex e-con e-child"
                                    data-id="666fae3" data-element_type="container">
                                    <div class="elementor-element elementor-element-ad50eeb elementor-widget elementor-widget-heading"
                                        data-id="ad50eeb" data-element_type="widget" data-widget_type="heading.default">
                                        <div class="elementor-widget-container">
                                            <h2 class="elementor-heading-title elementor-size-default">"Embrace the future with us."
                                            </h2>
                                        </div>
                                    </div>
                                    <div class="elementor-element elementor-element-b6e4a45 elementor-widget elementor-widget-heading"
                                        data-id="b6e4a45" data-element_type="widget" data-widget_type="heading.default">
                                        <div class="elementor-widget-container">
                                            <p class="elementor-heading-title elementor-size-default">"Join the vibrant community of creators, artists, and developers already harnessing the power of DSC-LaB."</p>
                                        </div>
                                    </div>
                                    <div class="elementor-element elementor-element-32e8152 elementor-widget elementor-widget-tg-btn"
                                        data-id="32e8152" data-element_type="widget" data-widget_type="tg-btn.default">
                                        <div class="elementor-widget-container">


                                            <a href="/login" target="_self" rel="nofollow"
                                                class="btn btn2 show-arrow btnnew text-white">
                                                Login </a>

                                        </div>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-72229c9 elementor-widget elementor-widget-image"
                                    data-id="72229c9" data-element_type="widget" data-widget_type="image.default">
                                    <div class="elementor-widget-container">
                                        <img loading="lazy" decoding="async" width="405" height="404"
                                            src="wp-content/uploads/2024/04/cta.png"
                                            class="attachment-full size-full wp-image-1123" alt=""
                                            //srcset="https://iko.themegenix.net/blockchain/wp-content/uploads/2024/04/cta.png 405w, https://iko.themegenix.net/blockchain/wp-content/uploads/2024/04/cta-300x300.png 300w, https://iko.themegenix.net/blockchain/wp-content/uploads/2024/04/cta-150x150.png 150w, https://iko.themegenix.net/blockchain/wp-content/uploads/2024/04/cta-350x350.png 350w"
                                            sizes="(max-width: 405px) 100vw, 405px" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <div data-elementor-type="wp-post" data-elementor-id="539" class="elementor elementor-539">
                <div class="elementor-element elementor-element-3bebaff e-con-full e-flex e-con e-parent" data-id="3bebaff"
                    data-element_type="container" data-core-v316-plus="true">
                    <div class="elementor-element elementor-element-96a9234 position-static elementor-widget elementor-widget-html"
                        data-id="96a9234" data-element_type="widget" data-widget_type="html.default">
                        <div class="elementor-widget-container">
                            <div class="footer-gradient-shape"></div>
                        </div>
                    </div>
                    <div class="elementor-element elementor-element-6bf6dc7 e-grid e-con-boxed e-con e-child" data-id="6bf6dc7"
                        data-element_type="container">
                        <div class="e-con-inner">
                            <div class="elementor-element elementor-element-40f4141 e-con-full e-flex e-con e-child"
                                data-id="40f4141" data-element_type="container">
                                <div class="elementor-element elementor-element-609d349 elementor-widget elementor-widget-image"
                                    data-id="609d349" data-element_type="widget" data-widget_type="image.default">
                                    <div class="elementor-widget-container">
                                        <Link to="/">
                                            <img src="./new.png" alt="IKO" className='logotext' />
                                        </Link>
                                    </div>
                                </div>

                                <div class="elementor-element elementor-element-924bab6 elementor-widget elementor-widget-heading"
                                    data-id="924bab6" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <p class="elementor-heading-title elementor-size-default">DSC-LaB: Leading decentralized digital education and referral program powered by blockchain smart contracts, pioneering global advancement in learning and rewards.</p>
                                    </div>

                                </div>



                            </div>
                            <div class="elementor-element elementor-element-8b8abf8 e-con-full e-flex e-con e-child"
                                data-id="8b8abf8" data-element_type="container">


                                <div class="elementor-element elementor-element-dfedce5 elementor-widget elementor-widget-iconlist"

                                    data-id="dfedce5" data-element_type="widget" data-widget_type="iconlist.default">

                                    <div class="elementor-widget-container">

                                        <div class="social-btn justify-content-center justify-content-lg-start">
                                            <a href="#" target='_blank'>
                                                <i aria-hidden="true" class="fab fa-facebook-f"></i> </a>
                                            <a href="https://x.com/DSC_Blockchain" target='_blank'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                    viewBox="0 0 16 16" fill="none">
                                                    <path
                                                        d="M9.52219 6.77491L15.4786 0H14.0671L8.89518 5.88256L4.76438 0H0L6.24657 8.89547L0 16H1.41155L6.87322 9.78779L11.2356 16H16L9.52219 6.77491ZM7.58888 8.97384L6.95597 8.08805L1.92015 1.03974H4.08821L8.15218 6.72796L8.78508 7.61374L14.0677 15.0076H11.8997L7.58888 8.97384Z"
                                                        fill="currentColor"></path>
                                                </svg> </a>
                                            <a href="https://www.instagram.com/dscblockchain?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target='_blank'>
                                                <i aria-hidden="true" class="fab fa-instagram"></i> </a>
                                            <a href="https://t.me/dscblockchainsupport" target='_blank'>
                                                <i aria-hidden="true" class="fab fa-telegram-plane"></i> </a>
                                        </div>

                                    </div>
                                </div>

                                <div class="elementor-element elementor-element-59b9cd7 e-con-full mt-auto e-flex e-con e-child"
                                    data-id="59b9cd7" data-element_type="container">
                                    <div class="elementor-element elementor-element-04d48a4 elementor-widget elementor-widget-heading"
                                        data-id="04d48a4" data-element_type="widget" data-widget_type="heading.default">
                                        <div class="elementor-widget-container">
                                            <p class="elementor-heading-title elementor-size-default">Copyright © 2024 DSC-LaB. All
                                                rights reserved.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-2d3506e e-con-full e-flex e-con e-child"
                                data-id="2d3506e" data-element_type="container">
                                <div class="elementor-element elementor-element-04d8b85 elementor-widget elementor-widget-heading"
                                    data-id="04d8b85" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <h2 class="elementor-heading-title elementor-size-default">SIGN UP FOR EMAIL UPDATES
                                        </h2>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-9ee2216 elementor-widget elementor-widget-heading"
                                    data-id="9ee2216" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <p class="elementor-heading-title elementor-size-default">Sign up with your email
                                            address to receive news and updates</p>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-cd9e38a elementor-widget elementor-widget-shortcode"
                                    data-id="cd9e38a" data-element_type="widget" data-widget_type="shortcode.default">
                                    <div class="elementor-widget-container">
                                        <div class="elementor-shortcode">

                                            <form id="mc4wp-form-1" class="mc4wp-form mc4wp-form-753" method="post"
                                                data-id="753" data-name="Footer Newsletter">
                                                <div class="mc4wp-form-fields">
                                                    <div class="newsletter-form">
                                                        <div class="form-group">
                                                            <input class="form-control" type="email" name="EMAIL"
                                                                placeholder="Your Email Address" required />
                                                        </div>
                                                        <button type="submit" class="btn btn5">Subscribe</button>
                                                    </div>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <ul id="menu-1-8e54fb7" class="navigation text-center mt-0 mb-5">

                        <li id="menu-item-1275" class="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-1275 fs-4"><Link to="/disclaimer" aria-current="page">Disclaimer</Link></li>

                    </ul>

                </div>
            </div>

        </div>
    )
}
