import React, { useEffect, useState } from "react";
import logo from "./111.png";
import { useSelector } from "react-redux";
import { getDashBoardDetails, getUserDetailsByWallet } from "../../../helpers/api";

const Form = () => {
    const openWhatsApp = (phoneNumber, walletAddress, id) => {
        // Construct the message
        const message = `Hey , this is my Wallet Address: ${walletAddress} and ID: ${id}`;

        // Encode the message
        const encodedMessage = encodeURIComponent(message);

        // Construct the WhatsApp URL
        const url = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

        // Open the URL
        window.open(url, '_blank');
    };
    const { walletAddress } = useSelector((action) => {
        return action.auth;
    });
    const [userDashData, setUserData] = useState("")

    useEffect(() => {
        getUserDetailsByWallet(walletAddress).then((res) => {
            setUserData(res);
    
          });


    }, [walletAddress]);

    console.log(userDashData,"userDashData:::")
    return (
        <div>
            <div className="row">
                <div className="col-lg-12 mt-5">
                    <div className="profile card card-body px-3 pt-4 pb-0">
                        <div className="profile-head">
                            <div className="photo-content ">
                                <div className="cover-photo rounded"></div>
                            </div>
                            <div className="profile-info">
                                <div className="profile-photo">
                                    <img
                                        src={logo}
                                        className="img-fluid rounded-circle"
                                        alt="profile"
                                    />
                                </div>
                                <div className="profile-details">
                                    <div className="profile-name px-3 pt-2">
                                        <button className="btn btn-info" onClick={() => openWhatsApp('12349013297', walletAddress, userDashData?.userDetails?.userId)}>
                                            Contact via WhatsApp →
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Form;
