import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import { Pagination } from "@mui/material";
import { RewardStatus, getStakedIncome } from "../../../helpers/api";
import { useSelector } from "react-redux";
import { Loader } from "../Dashboard/Loader";
import Countdown, { zeroPad } from "react-countdown";
import "bootstrap-daterangepicker/daterangepicker.css";

const Reward = () => {
  const { walletAddress } = useSelector((action) => {
    return action.auth;
  });

  const [apidata, setApidata] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    RewardStatus(walletAddress).then((res) => {
      setApidata(res?.data[0]);
      setLoading(true);
    });
  }, [walletAddress]);

  const Completionist = () => <span className="text-danger">Expired</span>;

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      return (
        <span className="header-label fs-14 grey">
          <span className="rounded" style={{ width: "56px" }}>
            Days {zeroPad(days)}
          </span>
          :{" "}
          <span className="rounded ms-1">
            {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
          </span>
        </span>
      );
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-xxl-12">
          <div className="card">
            {!loading ? (
              <Loader />
            ) : (
              <>
                <div className="card-header border-0 pb-2 flex-wrap">
                  <h4 className="heading me-2">Reward List</h4>
                </div>
                <div className="card-body pt-2">
                  <div
                    id="history_wrapper"
                    className="table-responsive dataTablehistory"
                  >
                    <div className="dataTables_wrapper no-footer">
                      <table
                        id="example"
                        className="table shadow-hover dataTable display"
                        style={{ minWidth: "845px" }}
                      >
                        <thead>
                          <tr>
                            <th>RANK</th>
                            <th>Target($)</th>
                            <th>Remaining Days</th>
                            <th>Achieve Status</th>
                            <th>Achieve Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* {console.log(apidata, "apidata:")} */}
                          <tr>
                            <td>Executive</td>
                            <td>2000</td>
                            <td>
                              {" "}
                              <Countdown
                                date={
                                  Date.now() +
                                  (moment(
                                    apidata?.executive_expiry_date
                                  ).valueOf() -
                                    Date.now())
                                }
                                renderer={renderer}
                              />
                            </td>
                            <td>
                              {apidata?.executive_status == 0 ? (
                                <span className="badge badge-primary">
                               N/A
                                </span>
                              ) : apidata?.executive_status == 1 ? (
                                <span className="badge badge-success">
                                  {" "}
                                  Achieved
                                </span>
                              ) : apidata?.executive_status == 3 ? (
                                <span className="badge badge-danger">
                                  Expired
                                </span>
                              ) : null}
                            </td>
                            <th>{apidata?.executive_achived_date==null?"N/A":apidata?.executive_achived_date}</th>
                          </tr>
                          <tr>
                            <td>Team Leader</td>
                            <td>6000</td>
                            <td>
                              {" "}
                              <Countdown
                                date={
                                  Date.now() +
                                  (moment(
                                    apidata?.teamleader_expiry_date
                                  ).valueOf() -
                                    Date.now())
                                }
                                renderer={renderer}
                              />
                            </td>
                            <td>
                              {apidata?.teamleader_status == 0 ? (
                                <span className="badge badge-primary">
                               N/A
                                </span>
                              ) : apidata?.teamleader_status == 1 ? (
                                <span className="badge badge-success">
                                  {" "}
                                  Achieved
                                </span>
                              ) : apidata?.teamleader_status == 3 ? (
                                <span className="badge badge-danger">
                                  Expired
                                </span>
                              ) : null}
                            </td>
                            <th>{apidata?.teamleader_achived_date==null?"N/A":apidata?.teamleader_achived_date}</th>
                          </tr>
                          <tr>
                            <td>Manager</td>
                            <td>15000</td>
                            <td>
                              {" "}
                              <Countdown
                                date={
                                  Date.now() +
                                  (moment(
                                    apidata?.manager_expiry_date
                                  ).valueOf() -
                                    Date.now())
                                }
                                renderer={renderer}
                              />
                            </td>
                            <td>
                              {apidata?.manager_status == 0 ? (
                                <span className="badge badge-primary">
                               N/A
                                </span>
                              ) : apidata?.manager_status == 1 ? (
                                <span className="badge badge-success">
                                  {" "}
                                  Achieved
                                </span>
                              ) : apidata?.manager_status == 3 ? (
                                <span className="badge badge-danger">
                                  Expired
                                </span>
                              ) : null}
                            </td>
                            <th>{apidata?.manager_achived_date==null?"N/A":apidata?.manager_achived_date}</th>
                          </tr>

                          <tr>
                            <td>Sr Manager</td>
                            <td>40000</td>
                            <td>
                              {" "}
                              <Countdown
                                date={
                                  Date.now() +
                                  (moment(
                                    apidata?.srmanager_expiry_date
                                  ).valueOf() -
                                    Date.now())
                                }
                                renderer={renderer}
                              />
                            </td>
                            <td>
                              {apidata?.srmanager_status == 0 ? (
                                <span className="badge badge-primary">
                               N/A
                                </span>
                              ) : apidata?.srmanager_status == 1 ? (
                                <span className="badge badge-success">
                                  {" "}
                                  Achieved
                                </span>
                              ) : apidata?.srmanager_status == 3 ? (
                                <span className="badge badge-danger">
                                  Expired
                                </span>
                              ) : null}
                            </td>
                            <th>{apidata?.srmanager_achived_date==null?"N/A":apidata?.srmanager_achived_date}</th>
                          </tr>
                          <tr>
                            <td>Branch Manager</td>
                            <td>100000</td>
                            <td>
                              {" "}
                              <Countdown
                                date={
                                  Date.now() +
                                  (moment(
                                    apidata?.bmanager_expiry_date
                                  ).valueOf() -
                                    Date.now())
                                }
                                renderer={renderer}
                              />
                            </td>
                            <td>
                              {apidata?.bmanager_status == 0 ? (
                                <span className="badge badge-primary">
                               N/A
                                </span>
                              ) : apidata?.bmanager_status == 1 ? (
                                <span className="badge badge-success">
                                  {" "}
                                  Achieved
                                </span>
                              ) : apidata?.bmanager_status == 3 ? (
                                <span className="badge badge-danger">
                                  Expired
                                </span>
                              ) : null}
                            </td>
                            <th>{apidata?.bmanager_achived_date==null?"N/A":apidata?.bmanager_achived_date}</th>
                          </tr>
                          <tr>
                            <td>Zonal Manager</td>
                            <td>300000</td>
                            <td>
                              {" "}
                              <Countdown
                                date={
                                  Date.now() +
                                  (moment(
                                    apidata?.zmanager_expiry_date
                                  ).valueOf() -
                                    Date.now())
                                }
                                renderer={renderer}
                              />
                            </td>
                            <td>
                              {apidata?.zmanager_status == 0 ? (
                                <span className="badge badge-primary">
                               N/A
                                </span>
                              ) : apidata?.zmanager_status == 1 ? (
                                <span className="badge badge-success">
                                  {" "}
                                  Achieved
                                </span>
                              ) : apidata?.zmanager_status == 3 ? (
                                <span className="badge badge-danger">
                                  Expired
                                </span>
                              ) : null}
                            </td>
                            <th>{apidata?.zmanager_achived_date==null?"N/A":apidata?.zmanager_achived_date}</th>
                          </tr>
                          <tr>
                            <td>State Head</td>
                            <td>1500000</td>
                            <td>
                              {" "}
                              <Countdown
                                date={
                                  Date.now() +
                                  (moment(
                                    apidata?.state_head_expiry_date
                                  ).valueOf() -
                                    Date.now())
                                }
                                renderer={renderer}
                              />
                            </td>
                            <td>
                              {apidata?.state_head_status == 0 ? (
                                <span className="badge badge-primary">
                               N/A
                                </span>
                              ) : apidata?.state_head_status == 1 ? (
                                <span className="badge badge-success">
                                  {" "}
                                  Achieved
                                </span>
                              ) : apidata?.state_head_status == 3 ? (
                                <span className="badge badge-danger">
                                  Expired
                                </span>
                              ) : null}
                            </td>
                            <th>{apidata?.state_head_achived_date==null?"N/A":apidata?.state_head_achived_date}</th>
                          </tr>
                          <tr>
                            <td>Country Head</td>
                            <td>4500000</td>
                            <td>LifeTime</td>
                            <td>
                              {apidata?.countryhead_status == 0 ? (
                                <span className="badge badge-primary">
                               N/A
                                </span>
                              ) : apidata?.countryhead_status == 1 ? (
                                <span className="badge badge-success">
                                  {" "}
                                  Achieved
                                </span>
                              ) : apidata?.countryhead_status == 3 ? (
                                <span className="badge badge-danger">
                                  Expired
                                </span>
                              ) : null}
                            </td>
                            <th>{apidata?.countryhead_achived_date==null?"N/A":apidata?.countryhead_achived_date}</th>
                          </tr>
                          <tr>
                            <td>Vice President</td>
                            <td>10000000</td>
                            <td>LifeTime</td>
                            <td>
                              {apidata?.vicepresidenc_status == 0 ? (
                                <span className="badge badge-primary">
                               N/A
                                </span>
                              ) : apidata?.vicepresidenc_status == 1 ? (
                                <span className="badge badge-success">
                                  {" "}
                                  Achieved
                                </span>
                              ) : apidata?.vicepresidenc_status == 3 ? (
                                <span className="badge badge-danger">
                                  Expired
                                </span>
                              ) : null}
                            </td>
                            <th>{apidata?.vicepresidenc_achived_date==null?"N/A":apidata?.vicepresidenc_achived_date}</th>
                          </tr>
                          <tr>
                            <td>Director</td>
                            <td>50000000</td>
                            <td>LifeTime</td>
                            <td>
                              {apidata?.director_status == 0 ? (
                                <span className="badge badge-primary">
                               N/A
                                </span>
                              ) : apidata?.director_status == 1 ? (
                                <span className="badge badge-success">
                                  {" "}
                                  Achieved
                                </span>
                              ) : apidata?.director_status == 3 ? (
                                <span className="badge badge-danger">
                                  Expired
                                </span>
                              ) : null}
                            </td>
                            <th>{apidata?.director_achived_date==null?"N/A":apidata?.director_achived_date}</th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reward;
